.delete_model {
    img {
        width: 56px;
        margin-right: 10px;
    }

    font-size: 14px;
}

.modal-header {
    border: none !important;
}

.modal-footer {
    border: none !important
}