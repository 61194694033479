@import "../../assets/styles/variables.scss";

// .seqrops-selected-tree-model{
//     border: 0.5px solid #0974ff;
//     border-radius: 3px;
// }

// .seqrops-node-name-section-wrapper{
//     padding:  0px 5px;
//     display: flex;
//     align-items: center;
//     min-width: 37%;
//     max-width: 70%;
//     float: left;
//     clear: left;
//     background-color: white;
//     position: relative;
//     top: 7px;

//     img{
//         width: 25px;
//     }
//     p{
//     font-size: 15px;
//     }
// }
// .seqrops-node-name-section{
//     padding:  0px 5px;
//     display: flex;
//     align-items: center;
//     min-width: 37%;
//     max-width: 70%;
//     float: left;
//     clear: left;
//     position: relative;
//     top: 7px;

//     img{
//         width: 25px;
//     }
//     p{
//     font-size: 15px;
//     }
// }
// .seqrops-node-name-edit-section{
//     bottom: 7px;
//     position: relative;
// }

// .seqrops-node-head-section{
//     width: 50px;
//     height: 29px;
//     color: white;
//     background-color: $mainModelBg;
//     line-height: 28px;
//     text-align: center;

//         p{
//             font-size: small;
//             text-align: center;
//         }
// }

// .seqrops-subnode-head-section{
//     width: 50px;
//     height: 29px;
//     color: white;
//     background-color: $subModelBg;
//     line-height: 28px;
//     text-align: center;

//         p{
//             font-size: small;
//             text-align: center;
//         }
// }

// .seqrops-relation-name-section {
//     left: 32px;
// }
// .seqrops-relation-head-section{
//     width: 50px;
//     height: 29px;
//     color: white;
//     background-color: $relationModelBg;
//     line-height: 28px;
//     text-align: center;

//         p{
//             font-size: small;
//             text-align: center;
//         }
// }

// .seqrops-node-option-section{
//     display: flex;
//     justify-content: flex-end;

// }

// .ml-6{
//     margin-left: 5.3em;
// }

/* tree */

.directory {
  font-size: 16px;
  color: black;
  text-transform: capitalize;
  user-select: none;
  padding: 20px;
  border-radius: 0.4em;
  img {
    width: 25px;
    height: 25px;
  }
}

.directory .tree,
.directory .tree-node,
.directory .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.directory .tree-branch-wrapper,
.directory .tree-node__leaf {
  outline: none;
  outline: none;
}

.directory .tree-node {
  cursor: pointer;
}

.directory .tree-node:hover {
  background: rgba(255, 255, 255, 0.1);
}

.directory .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
}

.directory .tree .tree-node--selected {
  background: rgb(196, 217, 241);
}

.directory .tree-node__branch {
  display: block;
  position: absolute;
  right: 10px;
  img {
    width: 18px;
  }
}

.directory .icon {
  vertical-align: middle;
  padding-right: 5px;
}

.tree-node-group li:before {
  content: "";
  height: 20px;
}

.tree-node-group li:after {
  left: -20px !important;
}

.tree-node-group--expanded:after {
  content: "";
  border-left: 1px solid #ddd;
  position: absolute;
  left: -10px;
  height: 40px;
  top: 4px;
}
.tree-branch-wrapper {
  margin: 13px 0 10px 10px !important;
}
.wtree li {
  width: 200px;
}

.bg-tree {
  background: white;
  border: 1px solid #e7e7e7;
  padding: 5px;
}

/* main node */
.seqrops-main-node {
  background: white;
  border: 1px solid #e7e7e7;
  position: relative;
  top: 22px;
  width: 22vh;
}
.removecaret.dropdown-toggle::after {
  display: none;
}

.error-message-section {
  font-size: 11px;
}

.node-options {
  button {
    padding: 5px 4px;
    background-color: none;
    background: none;
    border: none;
  }
  img {
    width: 100%;
  }
}
.seqrops-node-click-section {
  cursor: pointer;
}

.seqrops-node-head-logo-section {
  background-color: $relationModelBg;
  padding: 0px 7px;
  width: 1.5vw;
  height: 2.5vh;
  text-align: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  display: flex;

  p {
    margin-top: 12px;
    font-size: 11px;
    color: white;
  }
}

.seqrops-node-name-section {
  p {
    position: relative;
    top: 7px;
    left: 2px;
  }
}
.seqrops-node-click-section {
  position: relative;
  left: 10px;
}

span.d_tree_main {
  box-shadow: 0px 1px 9px rgb(0 0 0 / 6%);
  padding: 4px;
  border-radius: 5px;
  min-width: 210px;
  display: inline-block;
  background: white;
  position: relative;
  display: inline-flex;
  padding-right: 29px;
  margin-right: 40px;
}
.d_tree_inner span img {
  margin-right: 5px;
}

.d_tree_collapse {
  cursor: pointer;
}
.d_tree_inner span {
  color: #5e5e5e;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  display: flex;
}

.seqrops-node-option-section {
  position: absolute;
  right: 3px;
  top: 11px;
  img {
    width: 12px;
    cursor: pointer;
  }
  .dropdown-menu {
    font-size: 13px;
  }
}

.node-options.dropend {
  background: white;
}
span.addbtn_tree {
  
  position: absolute;
  right: 22px;
  top: 6px;
  background: white;
}