@import "../../assets/styles/variables.scss";

.seqrops-outer-console-section {
  padding: 10px;
}

.seqrops-model-name-section {
  overflow: auto;

  h1 {
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 0;
    width: 47vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.create-model-btn:hover {
  color: white;
  background-color: $selectedText;
}

.seqrops-publish-section {
  display: flex;
  justify-content: flex-end;
}

.seqrops-property-console-section {
  background: white;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
}

.loader-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &__spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #f5eaea;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}