

.seqrops-alarm-form-head-section {
    padding: 10px 0 0;

    h4 {
        padding-top: 7px;
        font-weight: 300;
    }

    p {
        font-size:14px;
    }
}



.seqrops-alarm-type-form-section{
        display: flex;
        flex-direction: column;
    p{
        display: flex;
    }
}
.seqrops-alarm-item{
    p{
        margin: 12%;
    }
}

.seqrops-alarm-item {
    background: white;
    box-shadow: 0 3px 12px -3px rgb(0 0 0 / 15%);
    border: 1px solid #eee;
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 4px;
}
.alarm-value {
    background: white;
    box-shadow: 0 3px 12px -3px rgb(0 0 0 / 15%);
    border: 1px solid #eee;
    padding: 10px;
    margin-bottom: 5px;
}
.seqrops-alarm-item span {
    font-weight: bold;
    font-size: 14px;
    color: #565656;
}