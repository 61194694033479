@import "../../assets/styles/variables.scss";

.seqrops-model-name-section {
  h4 {
    font-size: 14px;
  }
}

.seqrops-property-pattern-selection-section {
  display: flex;
  justify-content: flex-end;

  button {
    color: $textColor;
    border: none;
    background: none;
    &:hover{
      background: transparent;
    }
    &:focus{
      background-color: transparent !important;
    }
  }



  // .active{
    
  //       background-color:#60ABFB;
  //       color:#fff;
      
  // }
 
}

.seqrops-property-inner-console-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px 6px;
  background-color: #f2f4f7;
  border-radius: 4px;

  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .seqrops-property-title {
    justify-content: center;
    display: flex;
    width: 10%;
  }
}

.seqrops-add-property-btn {
  display: flex;
  justify-content: flex-end;
}

// .seqrops-property-listing-section {
//   position: relative;
//   top: 25px;
// }

.seqrops-property-pattern-selection-section {
  button {
    font-size: 13px;
  }
  
.active {
  border-bottom: 3px solid #60ABFB;
  font-weight: bold;
}
}
