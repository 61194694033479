$breakpoints: (
  i5se: 321px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  desktop_xl: 1800px
);




//Media Query min width
@mixin min($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {

    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
      
  } @else {

    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}



//Media Query max width
@mixin max($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {

    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

  } @else {

    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}



//Media Query between
@mixin minmax($lower, $upper) {

  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  
  } @else {

    @if (map-has-key($breakpoints, $lower) == false) {

      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    @if (map-has-key($breakpoints, $upper) == false) {

      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}



//@include max(sm)