.App {
  font-family: "Roboto", sans-serif !important;
}

.Toastify__toast-container {
  min-width: 400px;
}

.css-18nc3u2 .MuiOutlinedInput-root {
  padding: 0 !important;
}

/*loader */

.grid {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.loader::before {
  content: "";
  background: rgba(242, 235, 235, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  height: calc(100vh - 0px);
  z-index: 0;
}

.loader {
  width: 50vw;
  /* border: 1px solid mistyrose; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 999999;
  position: fixed;
  left: 0;
  right: 0;
}

#ld1 {
  position: relative;
  transform: rotate(45deg);
}

#ld1 div {
  height: 20px;
  width: 20px;
  // background: #fe4a49;
  background: #0072bb;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ld1 div:nth-child(1) {
  animation: ld1_div1 1s ease-in-out infinite;
}

#ld1 div:nth-child(2) {
  animation: ld1_div2 1s ease-in-out infinite;
}

#ld1 div:nth-child(3) {
  animation: ld1_div3 1s ease-in-out infinite;
}

@keyframes ld1_div1 {
  0% {
    top: 52.5px;
    // background: #fe4a49;
    background: #0072bb;
  }

  50% {
    top: -52.5px;
    // background: #59cd90;
    background: #0072bb;
  }

  100% {
    top: 52.5px;
    // background: #009fb7;
    background: #0072bb;
  }
}

@keyframes ld1_div2 {
  0% {
    right: 52.5px;
    // background: #fe4a49;
    background: #0072bb;
  }

  50% {
    right: -52.5px;
    // background: #fed766;
    background: #0072bb;
  }

  100% {
    right: 52.5px;
    // background: #59cd90;
    background: #0072bb;
  }
}

@keyframes ld1_div3 {
  0% {
    left: 52.5px;
    // background: #fe4a49;
    background: #0072bb;
  }

  50% {
    left: -52.5px;
    // background: #d91e36;
    background: #0072bb;
  }

  100% {
    left: 52.5px;
    // background: #fe4a49;
    background: #0072bb;
  }
}

#ld2 {
  display: flex;
  flex-direction: row;
}

#ld2 div {
  height: 20px;
  width: 5px;
  // background: #fe4a49;
  background: #0072bb;

  margin: 3px;
  border-radius: 25px;
}

#ld2 div:nth-child(1) {
  animation: ld2 1s ease-in-out infinite 0s;
}

#ld2 div:nth-child(2) {
  animation: ld2 1s ease-in-out infinite 0.1s;
}

#ld2 div:nth-child(3) {
  animation: ld2 1s ease-in-out infinite 0.2s;
}

#ld2 div:nth-child(4) {
  animation: ld2 1s ease-in-out infinite 0.3s;
}

#ld2 div:nth-child(5) {
  animation: ld2 1s ease-in-out infinite 0.4s;
}

#ld2 div:nth-child(6) {
  animation: ld2 1s ease-in-out infinite 0.5s;
}

#ld2 div:nth-child(7) {
  animation: ld2 1s ease-in-out infinite 0.6s;
}

@keyframes ld2 {
  0% {
    transform: scaleY(1);
    // background: #fed766;
    background: #0072bb;
  }

  25% {
    // background: #009fb7;
    background: #0072bb;
  }

  50% {
    transform: scaleY(2);
    // background: #59cd90;
    background: #0072bb;
  }

  75% {
    // background: #fe4a49;
    background: #0072bb;
  }

  100% {
    transform: scaleY(1);
    // background: #d91e36;
    background: #0072bb;
  }
}

#ld3 {
  position: relative;
  animation: outercontainer 4s linear infinite;
}

#ld3 div {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#ld3 div:nth-child(1) {
  top: 20px;
  // background: #59cd90;
  background: #0072bb;

  animation: ld3_div1 2s linear infinite;
}

#ld3 div:nth-child(2) {
  top: -20px;
  // background: #d91e36;
  background: #0072bb;
  animation: ld3_div2 2s linear infinite;
}

#ld3 div:nth-child(3) {
  left: 20px;
  background: #f39237;
  animation: ld3_div4 2s linear infinite;
}

#ld3 div:nth-child(4) {
  left: -20px;
  background: #0072bb;
  animation: ld3_div3 2s linear infinite;
}

@keyframes outercontainer {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ld3_div1 {
  0% {
    top: 20px;
  }

  25% {
    top: 0;
  }

  50% {
    top: 20px;
  }

  75% {
    top: 0;
  }

  100% {
    top: 20px;
  }
}

@keyframes ld3_div2 {
  0% {
    top: -20px;
  }

  25% {
    top: 0;
  }

  50% {
    top: -20px;
  }

  75% {
    top: 0;
  }

  100% {
    top: -20px;
  }
}

@keyframes ld3_div3 {
  0% {
    left: -20px;
  }

  25% {
    left: 0;
  }

  50% {
    left: -20px;
  }

  75% {
    left: 0;
  }

  100% {
    left: -20px;
  }
}

@keyframes ld3_div4 {
  0% {
    left: 20px;
  }

  25% {
    left: 0;
  }

  50% {
    left: 20px;
  }

  75% {
    left: 0;
  }

  100% {
    left: 20px;
  }
}

#ld4 {
  position: relative;
  display: flex;
  width: 25%;
  justify-content: space-between;
}

#ld4 div {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  // background: #d91e36;
  background: #0072bb;
}

#ld4 div:nth-child(1) {
  animation: ld4 3s linear infinite 0s;
}

#ld4 div:nth-child(2) {
  animation: ld4 3s linear infinite 0.15s;
}

#ld4 div:nth-child(3) {
  animation: ld4 3s linear infinite 0.3s;
}

#ld4 div:nth-child(4) {
  animation: ld4 3s linear infinite 0.45s;
}

@keyframes ld4 {
  0% {
    opacity: 0;
    transform: scale(0.3);
    // background: #59CD90;
    background: #0072bb;
  }

  25% {
    opacity: 1;
    transform: scale(1.8);
    background: #0072bb;
  }

  50% {
    opacity: 0;
    transform: scale(0.3);
    // background: #FE4A49;
    background: #0072bb;
  }

  75% {
    opacity: 1;
    transform: scale(1.8);
    // background: #fed766;
    background: #0072bb;
  }

  100% {
    opacity: 0;
  }
}

.loader-row {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  margin: 0 auto;
}

#light {
  background: #f9fdff;

  .cancel-create-model {
    color: #081f6b !important;
  }
  .cancel-unsaved-changes {
    color: #081f6b !important;
  }
  .no-delete-model {
    color: #081f6b !important;
  }
  .cancel-create-relation {
    color: #081f6b !important;
  }
  .cancel-create-model:hover {
    border: 1px solid #60abfb !important;
    background-color: #fff !important;
    color: #60abfb !important;
  }
  .cancel-unsaved-changes:hover {
    border: 1px solid #60abfb !important;
    color: #60abfb !important;
    background-color: #fff !important;
  }
  .no-delete-model:hover {
    border: 1px solid #60abfb !important;
    color: #60abfb !important;
    background-color: #fff !important;
  }
  .cancel-create-relation:hover {
    border: 1px solid #60abfb !important;
    background-color: #fff !important;
    color: #60abfb !important;
  }

  .seqrops-create-model-select-file-section {
    visibility: hidden;
    display: none;
  }
}

#dark {
  background: #111217 !important;

  .seqrops-dashboard-tile-wrapper {
    background: #181b1f !important;
    border-color: #24272d;
    box-shadow: none;
  }

  .seqrops-dashboard-tile-wrapper span {
    color: #b9bdce !important;
  }
  .seqrops-navbar-wrapper {
    background: #24272d !important;
    color: white !important;
  }

  .p-overlaypanel-content {
    background: #24272d !important;
    color: white !important;
  }
  .logout-section span {
    color: white !important;
  }
  .target-app-container {
    background: #24272d !important;
    color: white !important;
    border-color: #24272d;
  }

  .seqrops-username-box {
    .seqrops-username-text {
      background-color: #081f6b;
      color: #56bbff;
    }
    .seqrops-username {
      color: #b9bdce;
    }
  }

  .seqrops-model-name-section {
    h1 {
      color: #b9bdce !important;
    }
  }

  .seqrops-tree-model-title-section,
  .seqrops-model-name-section {
    h4 {
      color: #b9bdce !important;
    }
  }

  .seqrops-tree-console-wrapper {
    border: 1px solid #24272d !important;
  }
  .seqrops-property-console-section {
    border: 1px solid #24272d !important;
  }

  .seqrops-tree-item-dark {
    background-color: #24272d !important;
    border: 1px solid #555664 !important;
    .d_tree_inner {
      span {
        color: #b9bdce !important;
      }
    }
  }

  .seqrops-property-inner-console-section {
    background-color: #24272d !important;
    .seqrops-property-title {
      h3 {
        color: #b9bdce !important;
      }
    }
  }

  .seqrops-default-property-inner-console-section {
    background-color: #24272d !important;
  }

  .seqrops-property-title {
    color: #b9bdce !important;
  }
  .seqrops-property-pattern-selection-section {
    button {
      color: #b9bdce !important;
    }
  }
  .node-options.dropend {
    background: #24272d !important;
  }

  .seqrops-publish-section {
    :first-child {
      border: 1px solid #24272d !important;
      color: #b9bdce !important;
    }
    :first-child:hover {
      border: 1px solid #60abfb !important;
      color: #60abfb !important;
      background-color: #24272d !important;
    }
  }

  seqrops-no-data-found-section {
    p {
      color: #b9bdce;
    }
  }

  .seqrops-property-adding-section {
    background-color: #24272d !important;
    border: 1px solid #24272d !important;
    label {
      color: #b9bdce;
    }
  }
  .normal-text-color {
    color: #ccccdc !important;
  }
  .head-text-color {
    color: #ccccdc !important;
  }
  .panel-bg-color {
    background-color: #24272d !important;
  }
  .seqrops-basic-form-head-section {
    background-color: #24272d !important;
    border: 1px solid #24272d !important;
    color: #ccccdc !important;
  }

  .seqrops-alarm-form-section input {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }

  input:focus {
    border-color: white !important;
  }

  .seqrops-alarm-form-section input::placeholder {
    color: #ccccdc !important;
  }

  .seqrops-basic-form-section input,
  textarea,
  select {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }

  .seqrops-basic-form-section input::placeholder {
    color: #ccccdc !important;
  }

  input:focus {
    border-color: white !important;
  }

  .seqrops-property-adding-form-section input,
  textarea,
  select {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }

  .seqrops-property-adding-btn-section {
    background-color: #24272d !important;
    border: 1px solid #24272d !important;

    button {
      color: #b9bdce !important;
    }
    button:hover {
      color: #111217 !important;
    }
  }

  .seqrops-property-table-section {
    color: #b9bdce !important;
    border: 1px solid #2e374b !important;
  }

  .seqrops-property-table-section table {
    border: 1px solid #2e374b !important;
  }

  .seqrops-property-table-section table tr {
    &:nth-child(even) {
      background-color: #2e374b !important;
    }

    &:hover {
      background-color: #2e374b !important;
    }
  }

  .seqrops-property-table-section table th {
    border: 1px solid #2e374b !important;
    text-align: left;
    padding: 6px;
    font-size: 13px;
  }

  .seqrops-property-table-section table td {
    text-align: left;
    font-size: 13px;
    border: 1px solid #2e374b !important;
    padding: 6px;
  }

  .seqrops-dynamic-property-table-section {
    color: #b9bdce !important;
  }

  .seqrops-dynamic-property-table-section table {
    border: 1px solid #2e374b !important;
  }

  .seqrops-dynamic-property-table-section table tr {
    &:nth-child(even) {
      background-color: #2e374b !important;
    }

    &:hover {
      background-color: #2e374b !important;
    }
  }

  .seqrops-dynamic-property-table-section table th {
    border: 1px solid #2e374b !important;
    text-align: left;
    padding: 6px;
    font-size: 13px;
  }

  .seqrops-dynamic-property-table-section table td {
    text-align: left;
    font-size: 13px;
    border: 1px solid #2e374b !important;
    padding: 6px;
  }

  .d_tree_inner {
    span {
      label {
        color: #b9bdce !important;
      }
    }
  }

  .seqrops-alarm-item {
    background-color: #24272d !important;
    border: 1px solid #24272d !important;
    span {
      color: #b9bdce !important;
    }
  }
  .alarm-value {
    background-color: #24272d !important;
    border: 1px solid #24272d !important;
    input {
      background-color: #111217 !important;
      border: 1px solid #2e374b !important;
      color: #ccccdc !important;
      ::placeholder {
        color: #ccccdc !important;
      }
    }

    input:focus {
      border-color: white !important;
    }
  }

  .seqrops-side-bar-console-wrapper {
    background-color: #24272d !important;
  }

  .seqrops-model-list-item-wrapper {
    .model-item {
      color: #fdfdfd !important;
    }
  }

  .seqrops-search-model-section {
    background: #111217 !important;

    .input-group-text {
      background: #111217 !important;
    }

    input {
      background-color: #111217 !important;
      border: 1px solid #111217 !important;
      color: #ccccdc !important;
    }

    input:focus {
      border-color: white !important;
    }
    .filterIcon {
      background-color: #111217 !important;
    }
  }
  .seqrops-search-model-section input::placeholder {
    color: #ccccdc !important;
  }

  .value-section {
    background-color: #111217;
    border: 1px solid #24272d;
  }
  .sidbar--collapsed {
    background-color: #24272d;
  }

  .p-dialog-header {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .p-dialog-content {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .p-dialog-footer {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .modal-content {
    background-color: #24272d !important;
    color: #b9bdce !important;

    .modal-header {
      border-bottom: 1px solid #555664 !important;
    }

    .modal-footer {
      border-top: 1px solid #555664 !important;

      .cancel-create-model {
        color: #ccccdc !important;
      }
      .cancel-unsaved-changes {
        color: #ccccdc !important;
      }
      .no-delete-model {
        color: #ccccdc !important;
      }
      .cancel-create-relation {
        color: #ccccdc !important;
      }
      .cancel-create-model:hover {
        border: 1px solid #60abfb !important;
        background-color: #24272d !important;
        color: #60abfb !important;
      }
      .cancel-unsaved-changes:hover {
        border: 1px solid #60abfb !important;
        color: #60abfb !important;
        background-color: #24272d !important;
      }
      .no-delete-model:hover {
        border: 1px solid #60abfb !important;
        color: #60abfb !important;
        background-color: #24272d !important;
      }
      .cancel-create-relation:hover {
        border: 1px solid #60abfb !important;
        background-color: #24272d !important;
        color: #60abfb !important;
      }
    }
  }

  .seqrops-create-model-input-section {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }

  .seqrops-create-model-input-section:focus {
    border-color: white !important;
  }

  .seqrops-create-model-input-section::placeholder {
    color: #ccccdc !important;
  }

  .seqrops-create-model-description-section {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }

  .seqrops-create-model-description-section:focus {
    border-color: white !important;
  }

  .seqrops-create-model-description-section::placeholder {
    color: #ccccdc !important;
  }

  .seqrops-create-model-select-file-section {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
    visibility: hidden;
    display: none;
  }

  .custom-file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .custom-file-input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }

  .custom-file-label {
    font-size: 16px;
    padding: 8px 12px;
    border: 2px solid #2e374b;
    border-radius: 4px;
    background-color: #111217;
    color: #fff;
    cursor: pointer;
    display: inline-block;
  }

  .custom-file-label:hover {
    background-color: #111217;
  }

  .selected-file {
    margin-top: 10px;
    color: red;
  }

  .seqrops-create-model-select-file-section:focus {
    border-color: white !important;
  }

  .form-label {
    color: #ccccdc !important;
  }

  .MuiPaper-root {
    background-color: #24272d !important;
    color: #b9bdce !important;
  }

  .form-control {
    background-color: #111217 !important;
    border: 1px solid #2e374b !important;
    color: #ccccdc !important;
  }
  .form-control:focus {
    border-color: white !important;
  }

  .dropdown-menu {
    background-color: #2e374b !important;
    border: 1px solid #555664 !important;

    .dropdown-item {
      color: #b9bdce !important;
    }

    .dropdown-item:hover {
      background-color: #24272d;
      color: #b9bdce !important;
    }
  }

  .p-overlaypanel-content {
    width: 100%;
    border: 1px solid #555664 !important;
    .rootNode {
      label {
        color: #b9bdce !important;
      }
      label:hover {
        color: #60abfb !important;
      }
    }
  }
}
