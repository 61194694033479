@import "../../assets/styles/variables.scss";

.seqrops-property-adding-form-head-section {
  padding: 10px 0 0;

  h4 {
    padding-top: 10px;
    font-weight: 300;
  }
  p {
    font-size: 14px;
  }
}

.seqrops-property-adding-form-section {

  img{
    width: 19px;
    &:first-child{
      margin-right: 10px;
    }
  }
  font-size: small;

  label {
    display: flex;
  }
  input:not([type="checkbox"]) {
    padding: 5px;
    display: flex;
    width: 100%;
  }

  .seqrops-property-adding-btn-section {
    display: flex;
    justify-content: flex-end;
  }
  .seqrops-property-adding-isRequired-input-section {
    padding: 24px;

    input {
      width: 15px;
    }
  }
  .seqrops-property-adding-isRequired-label-section {
    padding-top: 20px;
    right: 25px;
    position: relative;
  }
  .seqrops-property-adding-isRequired-input-section {
    input {
      position: relative;
      right: 22px;
    }
  }
}
.error-message-section {
  color: red;
}

// .round-checkbox{
// border-radius: 50%;
// box-sizing: border-box;
// outline: none;
//   position: relative;
// background-color: #fff;
// border: 2px solid #ddd;
// border-radius: 50%;
// padding: 10px;
// margin: 5px;
// }

.static-section {
  padding-bottom: 0 !important;
}
