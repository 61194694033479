@import "../../assets/styles/variables.scss";

.seqrops-create-model-input-section {
  width: 100%;
}
.seqrops-create-model-description-section {
  resize:none;
  height:10vh;
}
.seqrops-create-model-popup-cancelBtn {
  background-color: white;
  color: $textColor;
  border: 0.5px solid $outline;
  padding: 5px 38px;
}
.seqrops-create-model-popup-close-btn {
  background: none;
  border: none;
}

label {
  font-size: smaller;
  color: $textColor;
}

.modal-content {
  padding: 15px 15px !important;
}

.error-message-section {
  margin-top: 2px !important;
  margin-bottom: 0;
}

.seqrops-edit-button {

  width: 2vw;
  height: 4vh;
}

.seqrops-model-icon{
  img{
    width:15px;
  }
  .delete-icon{
    width:35px;
  }
}