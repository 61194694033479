@import "assets/styles/variables.scss";

.seqrops-create-relationship-form-head-section {
  padding: 10px 0 0;

  h4 {
    padding-top: 10px;
    font-weight: 300;
  }

  p {
    font-size: 17px;
    display: flex;
    justify-content: flex-start;
  }
}

.seqrops-create-relationship-form-section {
  font-size: small;

  label {
    display: flex;
  }

  input {
    padding: 5px;
    display: flex;
    width: 100%;
  }

  select {
    background: none;
    border: gray 0.1px solid;
    padding: 7px;
    width: 100%;
  }

  .row {
    padding: 6px;
  }

  .error-message-section {
    width: 74%;
    color: red;
  }
}

.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2.7px 10px !important;
}

.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 2px !important;
}

.mt-2em {
  margin-top: 2em;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
}