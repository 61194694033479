$sidebarBg: #363f5e;
$textColor: #232323;
$createBtnOutline: #5170fd;
$selectedText: #5170fd;
$propertyTitleDivBg: #f4f4f4;
$publishButtonBg: #60abfb;
$treeConsoleBg: #F2F4F7;
$btnFilled: #5170fd;
$allModelsTab: #1363df;
$selectedModelsTab: #60abfb;
$mainModelBg: #0ab4d9;
$selectedModel: #aee1fc;
$hoverColor: #e8f7ff;
$outline: #363f5e;
$subModelBg: #1363df;
$relationModelBg: #0f7abc;
$alertBg: #c91212;

$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
$easeInOut: cubic-bezier(0.42, 0, 0.58, 1);
