.model-item {
  padding: 7px;
  border-color: #fff;
  color: #ffffff;
  border-radius: 5px;
  margin-right: 0;
  cursor: pointer;

  h1 {
    display: flex;
    justify-content: flex-start;
  }
  .model-name {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    h5 {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 23px;
      font-weight: 500;
      width: 181px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .drag-icon {
    padding: 0;
    line-height: 21px;
    cursor: grab;
  }
}

.model-item:hover {
  background-color: #60abfb;
}

.seqrops-selected-model {
  background-color: #03a9f4;
  color: #fff;
}

.seqrops-dragging {
  border: 2px solid red;
}
