@import "assets/styles/variables.scss";

.seqrops-inital-page-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.seqrops-no-model-image-text-section {
  padding-top: 10px;
  color: #a6a6b0;
}

.seqrops-create-model-button-section {
  padding-top: 20px;

  .initialscreen-createBtn {
    border: 1px solid $createBtnOutline;
    padding: 15px;
    border-radius: 3px;
    background-color: white;
    color: $createBtnOutline;
    transition: all 0.5s $ease;
    &:hover {
      border-color: transparent;
      color: white;
      background-color: $createBtnOutline;
    }
  }
}
.seqrops-no-model-image-section {
  display: flex;
  justify-content: center;
  img{
    width: 280px;
  }
}
.seqrops-no-model-image-text-section {
  font-size: 15px;
}
.seqrops-no-model-image-text-section {
  display: flex;
  justify-content: center;
}

.seqrops-create-model-button-section {
  display: flex;
  justify-content: center;
}
