@import "assets/styles/variables.scss";

.seqrops-property-table-section {
  width: 100%;
  overflow: auto;

  table {
    border-collapse: collapse;
    width: 100%;
    border: 0.5px solid rgb(237 230 230);

    tr {
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }

    th {
      border: 0.5px solid rgb(237 230 230);
      text-align: left;
      padding: 6px;
      font-size: 13px;
    }

    td {
      text-align: left;
      font-size: 13px;
      border-right: 0.5px solid rgb(237 230 230);

    }

    form td:last-child {
      display: flex;
      justify-content: space-evenly;
    }

    form * {
      font-size: 14px;
    }

    input {
      height: 22px;
      width: 28px;
    }

    button {
      background: none;
      border: none;
    }

    .seqrops-property-table-required-column {
      text-align: left;
    }

    .seqrops-property-table-constraints-column {
      flex-direction: column;

      .title-section {
        display: flex;
        justify-content: flex-end;
        padding: 0px;
        font-size: smaller;
        line-height: 0;
      }

      .value-section {
        display: flex;
        justify-content: center;
        font-weight: bold;
        background-color: $hoverColor;
        font-size: smaller;
        border: 1px solid #8abff7;
        padding: 4px;
        line-height: 5px;
        border-radius: 4px;
        margin-right: 7px;
        border: 1px solid #8abff7;
        padding: 6px;
        border-radius: 4px;
        margin-right: 7px;
        margin-left: 4px;
        line-height: 4px;
      }

      .constraints-data-section {
        margin-left: 0px;
      }
    }
  }

}


.seqrops-property-table-content-section:hover {
  background-color: $propertyTitleDivBg;
}

.seqrops-button-section {
  justify-content: left;

  border-right: none !important;
}

.seqrops-property-table-content-section {


  .seqrops-edit-button {

    width: 1vw;
    height: 2vh;
  }

}

.seqrops-no-data-found-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
}

.seqrops-no-data-found-section {
  text-align: center;

  img {
    width: 200px;
  }

  p {
    padding: 10px;
    color: grey;
    font-size: 12px;
  }
}

.seqrops-property-table-section p {
  margin-bottom: 0;
}

.seqrops-property-table-section table tr td {
  padding: 6px;
}

.download_sec {
  a {
    display: block;
    width: 14px;
  }

}

.seqrops-default-property-inner-console-section {
  padding: 6px 15px 6px;
  background-color: #f2f4f7;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  img {
    cursor: pointer;

  }

}