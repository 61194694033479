@import "assets/styles/variables.scss";

.seqrops-relationship-input-section {
  width: 90%;
}
.seqrops-new-relationship-add-button {
  background: none;
  border: none;
}
.seqrops-relationship-popup-cancelBtn {
  background-color: white;
  color: $textColor;
  border: 0.5px solid $outline;
  padding: 5px 38px;
}
.seqrops-relationship-popup-close-btn {
  background: none;
  border: none;
}

.seqrops-view-existing-relation-details-section {
  .property-item {
    background-color: skyblue;
    padding: 2px 5px;
  }
}

label {
  font-size: smaller;
  color: $textColor;
}

.modal-content {
  padding: 15px 15px;
}
.seqrops-relationship-createBtn {
  padding: 5px 33px;
}

select {
  margin-right: 10px;
  width: 95%;
  background: none;
}

//
.MuiAutocomplete-root {
  width: 100%;
}

.error{
  color: red;
}