@import "assets/styles/variables.scss";
//
$border: #ddd;
$border-hover: #aaa;
$bg-hover: #eee;
$text: #888;
$text-hover: #000;
$ident: 20px;
$left: -($ident);
ul {
  margin-left: $ident;
}

.seqrops-tree-model-section {
  // padding-left: 60px;
  // padding-top: 10px;
}

.seqrops-tree-model-title-section {
  margin-top: 20px;
  margin-left: 15px;

  h4 {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: bold;
    color: #454545;
  }
}
.seqrops-tree-console-wrapper {
  overflow: auto;
  background-color: $treeConsoleBg;
  border: 1px solid #e7e7e7;
  height: calc(100vh - 120px);
  border-radius: 3px;
}

//
.wtree {
  li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: $left;
      border-left: 1px solid $border;
      border-bottom: 1px solid $border;
      width: $ident;
      height: 15px;
    }
    &:after {
      position: absolute;
      content: "";
      top: 5px;
      left: $left;
      border-left: 1px solid $border;
      border-top: 1px solid $border;
      width: $ident;
      height: 100%;
    }
    &:last-child:after {
      display: none;
    }
    span {
      display: block;
      border: 1px solid $border;
      padding: 10px;
      color: $text;
      text-decoration: none;
    }
  }
}

.wtree {
  li {
    span {
      &:hover,
      &:focus {
        background: $bg-hover;
        color: $text-hover;
        border: 1px solid $border-hover;
        & + ul {
          li {
            span {
              background: $bg-hover;
              color: $text-hover;
              border: 1px solid $border-hover;
            }
          }
        }
      }

      &:hover + ul,
      &:focus + ul {
        li {
          &:after,
          &:before {
            border-color: $border-hover;
          }
        }
      }
    }
  }
}
