.seqrops-basic-form-head-section {
  padding: 5px 0 5px;
  padding-left: 10px;
  border: 1px solid #e8f7ff;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: #fafcff;

  h4 {
    padding-top: 10px;
    font-weight: 300;
  }

  p {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }
}

.alarm-value {
  margin-top: -5px;
}

.seqrops-basic-form-section {
  input {
    padding: 2px 10px;
  }

  select {
    padding: 4px 10px;
    width: 100%;
    font-size: 14px;
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .description-input {
    resize: none;
    height: 7vh;
    border: 1px solid #ced4da !important;
  }
}

.seqrops-alarm-form-section {
  margin-left: 22px !important;
}

.seqrops-property-adding-section {
  background: #fafcff;
  border: 1px solid #e8f7ff;
  border-radius: 5px;
  padding-bottom: 15px;
  min-height: 170px;
}

.seqrops-property-adding-btn-section {
  display: flex;
  justify-content: flex-end;
}

.seqrops-basic-form-wrapper {
  max-height: calc(100vh - 431px);
  overflow: auto;
}

.seqrops-alarm-form-wrapper {
  max-height: 157px;
  overflow: auto;
}

.seqrops-property-adding-btn-section {
  position: sticky;
  bottom: 0;
  background: white;
  border: 1px solid #eee;
  padding: 5px 24px 5px 3px;
  border-radius: 5px;
}

.form-control {
  padding: 4px 10px !important;
}

.form-control.form-control--multiLine {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.analog-values
  .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  height: 32px !important;
  border-radius: 0.375rem;
  align-content: center;
  border: none;
}

.computed-list-section {
  .form-control__input {
    border: none !important;
  }

  .form-control__input:focus-visible {
    outline: 0.5px solid #6cb4d5 !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0.375rem !important;
  }
}

.unitAutocomplete {
  .MuiAutocomplete-endAdornment button {
    & :before {
      content: "";
      background: white;
      width: 40px;
      height: auto;
      position: absolute;
      z-index: 99999;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &:after {
      content: "";
      background-image: url(/assets/images/arrow_down.svg);
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;
      position: absolute;
      left: 0;
      right: 0;
      width: 36px;
      height: 100%;
    }
  }
}
// .unitDropDown {
//   .css-18nc3u2 .MuiOutlinedInput-root
// }
.MuiAutocomplete-input {
  padding: 4.1px 4px 4.2px 5px !important;
}
.analog-values
  .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  height: 31px !important;
}
